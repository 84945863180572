<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <v-row>
          <v-col class="col-12 pt-0 pb-2">
            <h1>Edit {{ supplier.name }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 pt-0 pb-0">
            <router-link :to="`/purchasing/suppliers-view/${supplier.id}`">
              <v-btn small>View</v-btn>
            </router-link>
            &nbsp;
            <router-link :to="`/purchasing/raise-po/${supplier.id}`">
              <v-btn small>Raise PO</v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              hint="Required"
              persistent-hint
              v-model="supplier.name"
              label="Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <label>Category</label>
            <v-select
              v-model="supplier.supplier_category_id"
              :items="supplierCategories"
              item-value="id"
              item-text="category"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              hint="Required"
              persistent-hint
              v-model="supplier.address_1"
              label="Address One"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.address_2"
              label="Address Two"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.address_3"
              label="Address Three"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.address_4"
              label="Address Four"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
               hint="Required"
               persistent-hint
              v-model="supplier.post_code"
              label="Postcode"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.telephone"
              label="Telephone"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.email"
              label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.main_contact"
              label="Main Contact"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-text-field
              v-model="supplier.type_of_supply"
              label="Type of supply"
              hint="The type of supply, i.e. Paint, office supplies."
              persistent-hint></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-4">
            <v-btn
              @click="saveSupplier">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'UpdateSupplier',
  data() {
    return {
      supplier: {
        supplier_category_id: 0,
        name: '',
        address_1: '',
        address_2: '',
        address_3: '',
        address_4: '',
        post_code: '',
        telephone: '',
        fax: '',
        email: '',
        main_contact: '',
        type_of_supply: '',
      },
      supplierCategories: [],
      message: '',
      showMessage: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    saveSupplier() {
      if (
        this.supplier.name.length > 0
        && this.supplier.address_1.length > 0
        && this.supplier.post_code.length > 0
        ) {
        axios.post(`/suppliers/update.json?token=${this.token}`, this.supplier)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.message = 'Please complete all required fields: Name, Address One and Postcode';
        this.showMessage = true;
      }
    },
    getSupplierCategories() {
      axios.get(`/supplierCategories/getAll.json?token=${this.token}`)
        .then((response) => {
          this.supplierCategories = response.data.categories;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSupplier() {
      const supplierId = this.$route.params.id;
      axios.get(`/suppliers/get/${supplierId}.json?token=${this.token}`)
        .then((response) => {
          this.supplier = response.data.supplier;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSupplier();
    this.getSupplierCategories();
  },
}
</script>
